<template>
  <div class="roleHeader flex gap-5 text-base-content">
    <HeaderBlock
      v-for="(role, id) in roles"
      :key="id"
      class="roleHeader__item"
      :title="role"
    />
    <div class="roleHeader__item font-semibold text-sm w-24">Use In Case Creation</div>
    <div class="roleHeader__item font-semibold text-sm w-24">Report Visibility</div>
  </div>
</template>

<script>
import HeaderBlock from "./components/header-block.vue";

export default {
  name: 'field-roles-header',
  components: {
    HeaderBlock,
  },
  data() {
    return {
      roles: [
        'Analyst',
        'Subject',
        'Client',
        'Referee',
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
</style>