<template>
    <div
        class="field-tag flex items-center justify-center font-semibold rounded-full px-2 bg-white shadow w-24 h-10 text-center text-sm leading-snug text-base-content"
        :title="title"
        ref="container"
    >
        <span ref="output">{{ label }}</span>
    </div>
</template>

<script>
export default {
    name: "FieldTag",
    props: {
        label: {
            type: String,
        },
        title: {
            type: String,
        },
    },
    mounted() {
        this.resizeToFit();
    },
    methods: {
        resizeToFit() {
            const output = this.$refs.output;
            const outputContainer = this.$refs.container;

            let fontSize = window.getComputedStyle(output).fontSize;
            output.style.fontSize = (parseFloat(fontSize) - 1) + 'px';
            
            if(output.clientHeight >= outputContainer.clientHeight){
                this.resizeToFit();
            }
        }
    },
};
</script>

<style lang="scss" scoped></style>
