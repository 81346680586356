<template>
    <div class="flex items-center gap-3 w-full">
        <font-awesome-icon v-if="draggable" icon="grip-vertical" class="text-base-content-300" />
        <div
            :class="[showSidePanel && activeFieldPanel.field.id === element.id ?
                'border-solid  bg-blue-200 border-primary border-r-4 border-0' :
                'hover:bg-blue-200', fieldClass]"
            class="check-field bg-white transition-all duration-200 px-2 py-1 flex justify-between items-center gap-3 rounded-xl cursor-pointer flex-grow"
        >
            <div class="flex gap-3 items-center text-sm">
                <input
                    v-if="edited"
                    v-model="element.label"
                    @click.stop="$emit('fieldNameClick', element)"
                    @blur="onUpdateFieldLabel(section, element)"
                    :class="!element.label ? 'border border-dashed border-red-300 ' : ''"
                    type="text"
                    class="section_name bg-transparent min-w-100 border-0 outline-none p-1 text-md text-base-content focus:bg-gray-200 rounded focus:border-none border-b border-dashed border-gray-300"
                />
                <span
                    v-else
                    class="section_name bg-transparent min-w-100 border-0 outline-none p-1 text-md text-base-content font-semibold"
                >{{ fieldLabel }}</span>
                <div
                    v-if="!['Candidate Consent','Candidate Optional Consent'].includes(element.type)"
                    class="h-8 w-8 flex items-center justify-center rounded-half hover:bg-dvbrandcolor hover:text-white-text transition-all duration-150"
                    :class="edited ? 'bg-dvbrandcolor text-white-text' : 'text-primary'"
                    @click.stop="onEdit()"
                >
                    <font-awesome-icon icon="edit" class="cursor-pointer text-sm fill-current" />
                </div>
                <span>{{ element.name }}</span>
                <GapInfo
                    v-if="element.use_in_gap_validation" :element="element"
                    @click.native.stop="$emit('showGapValidationModal')"
                />
                <CascadedIcon
                    class="w-5 text-primary fill-current"
                    @click.stop="$emit('showFieldSettingsModal')"
                />
            </div>
            <div class="gap-3.5 flex items-center ml-auto">
                <div class="gap-3.5 flex items-center">
                    <FieldTag
                        v-if="element.ui_label"
                        :label="element.ui_label"
                        :title="`Field Type: ${element.ui_label}`"
                    />
                    <FieldTag
                        class="bg-gray-200"
                        :label="getLabel(element)"
                        :title="`Field Source: ${getLabel(element)}`"
                    />
                </div>
                <!-- <button @click.stop="$emit('handleShowPanel', { section, element })" type="button" class="px-4 py-1 font-light text-xs md:text-md text-white-text rounded-full bg-blue-500 border-2 border-white break-words hover:bg-blue-600">Configure Dependent Permissions</button> -->
                <FieldRolePanel
                    :field="element"
                    @PermissionChange="PermissionChange"
                    :allReportsRoles="allReportsRoles"
                    @change="change"
                    @changeCaseCreation="changeCaseCreation"
                />
            </div>
            <div class="flex items-center justify-center">
                <font-awesome-icon v-if="showDeleteIcon" class="cursor-pointer text-red-500 hover:text-red-700" @click.stop="$emit('handleDeleteField', { element, section })" icon="trash" />
            </div>
        </div>
    </div>
</template>

<script>
import FieldRolePanel from "./field-roles-panel";
import FieldTag from "./FieldTag";
const CascadedIcon = () => import("@shared/assets/icons/field-cascaded.svg");
const GapInfo = () => import("./GapInfo.vue");

export default {
    name: "fields",
    props: {
        showSidePanel: {
            type: Boolean,
            default: false,
        },
        activeFieldPanel: {
            type: Object,
            default: () => {},
        },
        element: {
            type: Object,
            default: () => {},
        },
        section: {
            type: Object,
            default: () => {},
        },
        allReportsRoles: {
            type: Array,
            default: () => [],
        },
        fieldClass: {
            type: String,
            default: '',
        },
        showDeleteIcon:{
            type: Boolean,
            default:true
        },
        updateFieldAttributesMethod: {
            type: Function,
        },
        draggable: {
            type:Boolean,
            default:true
        },
    },
    components: {
        FieldRolePanel,
        CascadedIcon,
        GapInfo,
        FieldTag,
    },
    data() {
        return {
            edited: false,
        }
    },
    computed: {
        fieldLabel() {
            return this.getFieldLabel(this.element);
        },
    },
    methods: {
        PermissionChange(role) {
            this.$emit("PermissionChange", role);
            // this.change();
        },
        async change({role, backup}, isRole) {
            try {
                await this.updateFieldAttributesMethod(this.section, this.element);
            } catch (error) {
                if (isRole) {
                    let match = this.element.roles.find((rl) => role.role_id === rl.role_id)
                    Object.assign(match, backup);
                }
            }
        },
        getFieldLabel(field){
            return ['Candidate Consent','Candidate Optional Consent'].includes(field.type) ?
                field.type.replace('Candidate','') :
                field.label;
        },
        changeCaseCreation($event) {
            this.$emit('changeCaseCreation',$event);
        },
        onEdit() {
            if(!this.element.label) {
                this.$toast.error("Label cannot be empty");
                return;
            }
            this.edited = !this.edited;
        },
        onUpdateFieldLabel(section, element) {
            if(!element.label) {
                this.$toast.error("Label cannot be empty");
                return;
            }
            this.$emit('updateFieldLabel', { section, element })
        },
        getLabel(element) {
            if (element?.source === 'system_fields') {
                            return "System Fields";
                        } else if (element?.source === 'cloned_system_fields') {
                            return "Cloned System Field";
                        } else if (element?.source === 'custom') {
                            return "Custom";
                        } else {
                            return "";
                        }
        }
    },
};
</script>

<style lang="scss" scoped>
.check-field {
    display: grid;
    grid-template-columns: minmax(400px, auto) 1fr 50px;
    grid-auto-flow: column;
}
</style>
