<template>
  <div class="rolePanel flex gap-2.5">
    <fieldset :disabled="isLoading" class="rolePanel__roles flex gap-4">
      <PanelBlock
        v-for="(role, id) in roles"
        :key="id"
        class="rolePanel__item"
        :role="role"
        @PermissionChange="PermissionChange"
        @change="change($event, true)"
      />
    </fieldset>
    <Checkbox
      @click.native.stop
      @input="changeCaseCreation(field,$event)"
      v-model="useInCaseCreation"
      :disabled="!isWritePermissionSelected"
    />
    <Dropdown
      class="rolePanel__item rolePanel__item--dropdown h-6"
      width="220px"
      placeholder="Assignee"
      @click.native.stop
      @input="change"
      v-model="report_visibility"
      :allowEmpty="false"
      :options="allReportsRoles"
      :config="{'label': 'name','track-by': 'id'}"
      openDirection=''
      reposition
    />
  </div>
</template>

<script>
import PanelBlock from "./components/panel-block.vue";
import Dropdown from '@shared/components/dropdown-base';
import Checkbox from "@/components/checkbox-alt";
import { fetchAllRoles  } from "@/modules/field-editor/services.js";

import { cloneDeep } from "lodash";
export default {
  name: 'field-roles-panel',
  props: {
    field: {
      type: Object,
      default: () => {},
    },
    allReportsRoles: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PanelBlock,
    Dropdown,
    Checkbox,
  },
  data() {
    return {
      allRoles: [],
    }
  },
  computed: {
    roles() {
      let roles_order = {
        'Analyst': 0,
        'Subject': 1,
        'Client': 2,
        'Referee': 3,
      };
      let allRoles = cloneDeep(this.field.roles);
      allRoles = allRoles.map((role) => ({
        ...role, 
        ...this.allRoles.find((rl) => rl.id === role.role_id)
      }));
      const role_list = allRoles.sort((a, b) => {
          return roles_order[a.title] > roles_order[b.title] ? 1: -1;
      })

      return role_list || [];
    },
    report_visibility: {
      get() {
        return this.allReportsRoles.find(r => r.id === this.field.report_visibility);
      },
      set(val) {
        this.field.report_visibility = val.id;
      },
    },
    useInCaseCreation: {
      get() {
        return this.roles.find(el => el.code === "SC-1").role_write && this.roles.find(el => el.code === "CL-AD").role_write && this.field.use_in_case_creation
      },
      set(val) {
        this.field.use_in_case_creation = val;
      },
    },
    isWritePermissionSelected(){
      return this.roles.find(el => el.code === "SC-1").role_write && this.roles.find(el => el.code === "CL-AD").role_write
    }
  },
  methods: {
    PermissionChange(role) {
      this.field.roles = cloneDeep(this.roles);
      this.$emit("PermissionChange", role);
    },
    change(payload, isRole) {
      if (payload)
        this.$emit('change', payload, isRole);
    },
    changeCaseCreation(field, $event) {
      this.$emit('changeCaseCreation', {field, event: $event});
    },
    fetchAllRoles,
  },
  async mounted() {
    this.allRoles = await this.fetchAllRoles();
  }
}
</script>

<style lang="scss" scoped>
@mixin rightDivider() {
  &::after {
    content: '';
    display: block;
    @apply border-l border-solid border-gray-300;
  }
}
.rolePanel {
  &__item {
    display: flex;
    &:not(:last-child) {
      @include rightDivider;
    }
    &--dropdown {
      // border-color: #CCE3FF;
      // background-color: #CCE3FF;
      @apply bg-primary-100 border-primary-100;
      @apply h-7;
      ::v-deep {
        .multiselect {
          // font-size: 8px;
          &__content-wrapper {
            // position: fixed !important;
          }
          // &__single {
          //   font-size: 8px;
          // }
        }
      }
    }
  }
  &__roles {
    @include rightDivider;
  }
}
::v-deep {
  .checkbox-alt {
    display: flex;
    align-items: center;
    ::v-deep {
      .input-wrapper {
        display: flex;
      }
    }
  }
  .dropdown .multiselect__content-wrapper{
      max-width: 0px;
      border-radius: 8px;
    }
}
</style>