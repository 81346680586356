<template>
    <div class="flex flex-col bg-white rounded-2xl border-2" v-if="sectionData && sectionData.length">
        <div class="p-3 panel-head bg-primary-300 font-bold text-base-content flex gap-3 justify-between items-center rounded-t-2xl">
            Sections
            <div class="flex gap-3 items-center">
                <span class="text-base-content text-sm flex items-center gap-2 cursor-pointer" v-if="isSectionExpanded" @click="expandSections"><font-awesome-icon icon="chevron-circle-up" class="text-base-content cursor-pointer text-lg" /> Collapse All</span>
                <span class="text-base-content text-sm flex items-center gap-2 cursor-pointer" v-else @click="expandSections"><font-awesome-icon icon="chevron-circle-down" class="text-base-content cursor-pointer text-lg" /> Expand All </span>
            </div>
        </div>
        <div class="w-full p-4 gap-2 flex flex-col overflow-auto scroll-bar">
            <div class="w-max min-w-full gap-2 flex flex-col">
                <transition>
                    <div class="pl-12 w-full flex" v-if="isAnySectionExpanded">
                        <RolesHeader class="role-panel ml-auto mr-36 pr-3"/>
                    </div>
                </transition>
                <div class="flex flex-col gap-6">
                    <draggable data-source="sectionData" :list="sectionData" draggable=".item" group="section" v-bind="dragOptions" @start="startDrag = true" @end="startDrag = false" class="list-group flex flex-col gap-4" @change="sectionReorder($event)">
                        <div class="list-group-item item shadow-sm rounded-xl bg-gray-100 p-3 border-0 flex flex-col gap-3 border-gray-300 border-solid" v-for="section in sectionList" :key="section.id">
                            <sectionDragable
                                :showSidePanel="showSidePanel"
                                @handleShowPanel="handleShowPanel($event.field, $event.section)"
                                @fieldNameClick="fieldNameClick($event)"
                                @handleDeleteField="handleDeleteField($event.field, $event.section)"
                                @updateFieldLabel="updateFieldLabel"
                                @handleFieldMove="handleFieldMove($event)"
                                @handleRemoveBlock="handleRemoveBlock($event.section, $event.element)"
                                @handleAddFieldChange="handleAddFieldChange($event)"
                                :section="section"
                                @handleCollapseBlock="handleCollapseBlock($event.section, $event.element)"
                                :checkData="checkData"
                                @AddNewBlock="AddNewBlock"
                                @handleRemoveSection="handleRemoveSection"
                                :activeFieldPanel="activeFieldPanel"
                                @handleUpdateBlock="handleUpdateBlock($event.section, $event.element)"
                                @saveBlockFields="saveBlockFields($event.section, $event.element)"
                                @showfieldAddModal="showfieldAddModal($event.section, $event.element)"
                                @showBlockModal="$emit('showBlockModal', $event)"
                                @updateSectionLabel="$emit('updateSectionLabel', $event)"
                                @PermissionChange="PermissionChange"
                                :hideAddFieldButtonInBlock="hideAddFieldButtonInBlock"
                                :allReportsRoles="allReportsRoles"
                                @showFieldSettingsModal="showFieldSettingsModal($event.field, $event.section)"
                                :updateSectionFieldsMethod="updateSectionFieldsMethod"
                                :updateBlockFieldsMethod="updateBlockFieldsMethod"
                                @changeCaseCreation="changeCaseCreation"
                                :updateFieldAttributesMethod="updateFieldAttributesMethod"
                                @showGapValidationModal="showGapValidationModal($event.field, $event.section)"
                            />
                        </div>
                    </draggable>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import sectionDragable from "./dragable-components/sectionDragable.vue";
import draggable from "vuedraggable";
import RolesHeader from "./roles-header"

export default {
    name: "section-lists",
    components: {
        sectionDragable,
        draggable,
        RolesHeader,
    },
    data() {
        return {};
    },
    props: ["sectionList", "allSectionExpanded", "checkData", "showSidePanel", "activeFieldPanel", "hideAddFieldButtonInBlock", "allReportsRoles", "updateSectionFieldsMethod", "updateBlockFieldsMethod", "updateFieldAttributesMethod"],
    computed: {
        dragOptions() {
            return {
                animation: 0,
                group: "description",
                disabled: false,
                ghostClass: "ghost",
            };
        },
        isSectionExpanded() {
            return this.sectionList.every(el => {
                // console.log('this.sectionList :>> ', this.sectionList);
                return el.expanded && el.fields.every(fld => {
                    if  (fld.entity_type === 'block') return fld.expanded 
                    else return true
                })
            }) ?? false
            // return this.allSectionExpanded;
        },
        isAnySectionExpanded() {
            return this.sectionData.some(sec => sec.expanded);
        },
        sectionData() {
            return this.sectionList;
        },
    },
    methods: {
        expandSections() {
            this.$emit("handleExpandAllSections");
        },
        startDrag() {
            this.$emit("drag");
        },
        sectionReorder(event) {
            this.$emit("sectionReorder", event);
        },
        AddNewBlock(payload) {
            this.$emit("AddNewBlock", payload);
        },
        handleRemoveSection(sectionData) {
            this.$emit("handleRemoveSection", sectionData);
        },
        handleShowPanel(field, section) {
            this.$emit("handleShowPanel", { field, section });
        },
        fieldNameClick(field) {
            this.$emit("fieldNameClick", field);
        },
        handleDeleteField(field, section) {
            this.$emit("handleDeleteField", { field, section });
        },
        updateFieldLabel(e) {
            this.$emit("updateFieldLabel", e);
        },
        handleRemoveBlock(section, element) {
            this.$emit("handleRemoveBlock", { section, element });
        },
        handleCollapseBlock(section, element) {
            // console.log(section, element, "asdasjdjbn");
            this.$emit("handleCollapseBlock", { section, element });
        },
        handleUpdateBlock(section, element) {
            this.$emit("handleUpdateBlock", { section, element });
        },
        saveBlockFields(section, element) {
            this.$emit("saveBlockFields", { section, element });
        },
        showfieldAddModal(section, element) {
            this.$emit("showfieldAddModal", { section, element });
        },
        handleFieldMove($event) {
            this.$emit("handleFieldMove", $event);
        },
        handleAddFieldChange($event) {
            this.$emit("handleAddFieldChange", $event);
        },
        PermissionChange(role) {
            this.$emit("PermissionChange", role);
        },
        showFieldSettingsModal(field, section) {
            this.$emit("showFieldSettingsModal", { field, section });
        },
        showGapValidationModal(field, section) {
            this.$emit("showGapValidationModal", { field, section });
        },
        changeCaseCreation(payload) {
            this.$emit('changeCaseCreation', payload);
        },
    },
};
</script>

<style lang="scss" scoped>
.role-panel {
    padding-left: 400px;
}
</style>
