<template>
    <div class="text-base-content">
        <div class="flex justify-between items-center">
            <div class="flex items-center space-x-4">
                <GripSectionIcon class="fill-current text-base-content" />
                <span class="text-base-content text-sm font-bold"> Section Name: </span>
                <input v-model="section.name" @click="sectionNameClick(section)" @blur="updateSectionLabel(section)" :class="!section.name ? 'border border-dashed border-red-300 ' : 'border-b border-dashed border-gray-300'" type="text" class="section_name min-w-100 border-0 outline-none p-1 text-md focus:bg-gray-200 rounded focus:border-none bg-gray-100" placeholder="ADD SECTION NAME" />
            </div>
            <div class="flex space-x-4 items-center">
                <Button
                    v-if="fieldsToSave"
                    class="btn-success btn-xs"
                    @click="updateSectionFields"
                    text="Save Fields"
                />
                <button class="outline-none text-base-content hover:text-primary-focus" v-if="section.expanded" @click="$emit('showBlockModal', section)" v-tippy content="Add Block">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 002.25-2.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v2.25A2.25 2.25 0 006 10.5zm0 9.75h2.25A2.25 2.25 0 0010.5 18v-2.25a2.25 2.25 0 00-2.25-2.25H6a2.25 2.25 0 00-2.25 2.25V18A2.25 2.25 0 006 20.25zm9.75-9.75H18a2.25 2.25 0 002.25-2.25V6A2.25 2.25 0 0018 3.75h-2.25A2.25 2.25 0 0013.5 6v2.25a2.25 2.25 0 002.25 2.25z" />
                    </svg>
                </button>
                <button class="outline-none float-right hover:text-primary-focus text-base-content" v-if="section.expanded" @click="showfieldAddModal(section)" v-tippy content="Add Field">
                    <AddFieldIcon class="h-5 w-5 cursor-pointer fill-current" />
                </button>

                <font-awesome-icon v-if="section.expanded" @click="handleCollapseSection(section)" icon="chevron-circle-up" class="hover:text-primary-focus cursor-pointer text-xl fill-current text-base-content" />

                <font-awesome-icon v-else @click="handleCollapseSection(section)" icon="chevron-circle-down" class="hover:text-primary-focus cursor-pointer text-xl fill-current text-base-content" />

                <font-awesome-icon @click="handleRemoveSection(section)" icon="times" class="cursor-pointer text-primary hover:text-primary-focus text-lg" />
            </div>
        </div>
        <div v-if="section.expanded" class="transition-all duration-200 mt-2">
            <!-- fields Dragables -->
            <draggable :id="section.id" data-source="juju" :list="section.fields" class="list-group flex flex-col gap-2 transition-all duration-200" draggable=".item" group="feilds" :sort="true" v-bind="dragOptions" @start="isDragging = true" @end="isDragging = false" @change="$emit('handleFieldMove' ,$event)" @add="$emit('handleAddFieldChange' ,$event)">
                <transition-group type="transition" name="flip-list" class="flex flex-col space-y-4">
                    <div class="list-group-item child item" v-for="element in section.fields" :key="element.id">
                        <template v-if="element.entity_type === 'field'">
                            <template v-if="element.is_cascaded">
                                <CascadedFields :element="element" 
                                :section="section" 
                                :showSidePanel="showSidePanel" 
                                :activeFieldPanel="activeFieldPanel" 
                                :allReportsRoles="allReportsRoles"
                                :updateFieldAttributesMethod="updateFieldAttributesMethod"
                                @fieldNameClick="fieldNameClick($event)" 
                                @handleDeleteField="handleDeleteField($event.element, $event.section)"
                                @updateFieldLabel="updateFieldLabel($event.element, $event.section)"
                                @showFieldSettingsModal="showFieldSettingsModal(element, section)"
                                @showGapValidationModal="showGapValidationModal(element, section)"
                                @PermissionChange="PermissionChange"
                                @change="updateFieldLabel($event.element, $event.section)"
                                @changeCaseCreation="changeCaseCreation(element)"
                            />
                            </template>
                            <template v-else>
                                <Fields :element="element" 
                                :section="section" 
                                :showSidePanel="showSidePanel" 
                                :activeFieldPanel="activeFieldPanel"
                                :allReportsRoles="allReportsRoles"
                                :updateFieldAttributesMethod="updateFieldAttributesMethod"
                                @fieldNameClick="fieldNameClick($event)" 
                                @handleDeleteField="handleDeleteField($event.element, $event.section)" 
                                @updateFieldLabel="updateFieldLabel($event.element, $event.section)"
                                @PermissionChange="PermissionChange" 
                                @change="updateFieldLabel($event.element, $event.section)"
                                @changeCaseCreation="changeCaseCreation(element)"
                                @showGapValidationModal="showGapValidationModal(element, section)"
                            />
                            </template>
                        </template>

                        <!-- Block Dragables -->
                        <div v-else>
                            <div
                                class="bg-white py-3.5 px-3 text-base-content flex justify-between items-center rounded-t-xl handleUpdateBlock"
                                :class="{
                                    'bg-blue-200 text-gray-300': element.created,
                                    'rounded-xl': !element.expanded,
                                }"
                            >
                                <div class="flex items-center gap-10">
                                    <h2 class="font-normal text-sm flex items-center gap-3" :class="element.created && 'text-primary'">
                                        <span v-if="element.name && !element.edited" class="font-semibold">{{ element.name }}</span>
                                        <input v-model="element.name" v-if="element.edited && !element.created" :class="!element.name ? 'border-b border-dashed border-red-300' : 'border-b border-dashed border-gray-300'" type="text" class="bg-transparent min-w-100 border-0 outline-none p-1 text-md focus:bg-gray-100 rounded-md" />
                                        <div v-if="!element.created" class="h-8 w-8 flex items-center justify-center rounded-half hover:bg-dvbrandcolor hover:text-white-text transition-all duration-150" :class="element.edited ? 'bg-dvbrandcolor text-white-text' : 'text-primary'" @click="element.edited = !element.edited">
                                            <font-awesome-icon icon="edit" class="cursor-pointer text-sm fill-current"  />
                                        </div>
                                        <span>{{ element.label }}</span>
                                    </h2>
                                    <div class="flex gap-5 items-center pr-10" v-if="element.expanded || element.edited">
                                        <div class="flex items-center gap-2 text-sm">
                                            Multi
                                            <Checkbox
                                                @click.native.stop
                                                v-model="element.block_multi"
                                                :disabled="!element.edited"
                                            />
                                        </div>
                                        <div class="flex items-center gap-2 text-sm">
                                            Min
                                            <input @keypress="isNumber($event)" :min="element.block_multi ? 1: ''" value="1" type="number" v-model="element.min_count" :disabled="!element.block_multi || (!element.edited && !element.created)" class="w-10 h-6 rounded-md text-center font-bold" />
                                        </div>
                                        <div class="flex items-center gap-2 text-sm">
                                            Max
                                            <input @keypress="isNumber($event)" :min="element.block_multi ? element.min_count: ''" value="1" type="number" v-model="element.max_count" :disabled="!element.block_multi || (!element.edited && !element.created)" class="w-10 h-6 rounded-md text-center font-bold" />
                                        </div>
                                        <Button class="btn-success btn-xs" :disabled="!element.name" @click="handleUpdateBlock(section, element)" v-if="element.edited && !element.created" text="save" />
                                    </div>
                                </div>

                                <div class="flex space-x-4 items-center pr-3">
                                    <Button v-if="showSaveBlockButton(element)" @click="saveBlockFields(section, element)" class="btn btn-xs btn-success border-1 border-solid float-right" text="Save block" />
                                    <Button
                                        v-if="blockFieldsToSave.includes(element.id)"
                                        class="btn-success btn-xs ml-auto"
                                        @click="updateBlockFields(element)"
                                        text="Save Fields"
                                    />
                                    <!-- <button class="outline-none float-right text-gray-800 hover:text-primary-focus" v-if="element.expanded && !hideAddFieldButtonInBlock" @click="showfieldAddModal(section, element)" v-tippy content="Add Field">
                                        <AddFieldIcon class="h-5 w-5 cursor-pointer fill-current" />
                                    </button> -->

                                    <span v-if="element.expanded" @click.stop="handleCollapseBlock(section, element)" class="flex items-center gap-2 cursor-pointer">
                                        <font-awesome-icon icon="chevron-circle-up" class="text-gray-800 hover:text-primary-focus cursor-pointer text-lg" />
                                    </span>
                                    <span v-else @click.stop="handleCollapseBlock(section, element)" class="flex items-center gap-2 cursor-pointer">
                                        <font-awesome-icon icon="chevron-circle-down" class="text-gray-800 hover:text-primary-focus cursor-pointer text-" />
                                    </span>

                                    <font-awesome-icon @click="handleRemoveBlock(section, element)" icon="times" class="cursor-pointer text-primary hover:text-primary-focus text-lg" />
                                </div>
                            </div>
                            <div v-if="element.expanded" class="transition-all duration-500 p-4 flex flex-col gap-3 bg-white rounded-b-xl">
                                <p v-if="!element.fields.length" class="flex items-center justify-center text-gray-700">Add Fields to block</p>
                                <!-- <draggable v-else :id="element.id" data-source="juju" :list="element.fields" class="list-group flex flex-col gap-2 transition-all duration-200" draggable=".item" :sort="true" @start="isDragging = true" @change="$emit('handleFieldMove' ,$event)" @end="$emit('handleAddFieldChange' ,$event)"> -->
                                <div
                                    v-else
                                    :id="element.id"
                                    class="list-group flex flex-col gap-2 transition-all duration-200"
                                >
                                    <transition-group type="transition" name="flip-list" class="flex flex-col gap-3">
                                        <div class="list-group-item child item" v-for="blockField in element.fields" :key="blockField.id">
                                            <template v-if="blockField.is_cascaded">
                                                <CascadedFields :showDeleteIcon="false" 
                                                fieldClass="bg-gray-100" 
                                                :element="blockField" 
                                                :section="section" 
                                                :showSidePanel="showSidePanel" 
                                                :allReportsRoles="allReportsRoles"
                                                :activeFieldPanel="activeFieldPanel" 
                                                :updateFieldAttributesMethod="updateFieldAttributesMethod"
                                                @fieldNameClick="fieldNameClick($event)" 
                                                @handleDeleteField="handleDeleteField($event.element, $event.section)" 
                                                @updateFieldLabel="updateFieldLabel($event.element, $event.section)"
                                                @PermissionChange="PermissionChange"
                                                @showFieldSettingsModal="showFieldSettingsModal(blockField, section)"
                                                @showGapValidationModal="showGapValidationModal(blockField, section)"
                                                @change="updateFieldLabel($event.element, $event.section)"
                                                @changeCaseCreation="changeCaseCreation(blockField)"
                                                :draggable="false"
                                            />
                                            </template>
                                            <template v-else>
                                                <Fields :showDeleteIcon="false" 
                                                fieldClass="bg-gray-100" 
                                                :element="blockField" 
                                                :section="section" 
                                                :showSidePanel="showSidePanel"
                                                :allReportsRoles="allReportsRoles" 
                                                :activeFieldPanel="activeFieldPanel" 
                                                :updateFieldAttributesMethod="updateFieldAttributesMethod"
                                                @fieldNameClick="fieldNameClick($event)" 
                                                @handleDeleteField="handleDeleteField($event.element, $event.section)" 
                                                @updateFieldLabel="updateFieldLabel($event.element, $event.section)"
                                                @PermissionChange="PermissionChange"
                                                @change="updateFieldLabel($event.element, $event.section)"
                                                @changeCaseCreation="changeCaseCreation(blockField)"
                                                @showGapValidationModal="showGapValidationModal(blockField, section)"
                                                :draggable="false"
                                            />
                                            </template>
                                        </div>
                                    </transition-group>
                                </div>
                                <!-- </draggable> -->
                                <div class="flex justify-end"></div>
                            </div>
                        </div>

                        <!-- End Block Dragables -->
                    </div>
                </transition-group>
            </draggable>
        </div>

    </div>
</template>

<script>
// import Input from "@/components/input";
import Button from "@/components/button";
import CascadedFields from "../CascadedFields.vue";
import Fields from "../Fields.vue";
import draggable from "vuedraggable";
import AddFieldIcon from "@/assets/icons/check-admin/add-field.svg";
import { isNumber } from "@/plugins/functions";
import Checkbox from "@/components/checkbox-alt";
import GripSectionIcon from '@/assets/icons/grip-section.svg';

const removeEmpty = (obj) => Object.fromEntries(Object.entries(obj).filter(([, v]) => v != null));

export default {
    name: "section-dragable",
    components: {
        // Input,
        Button,
        Fields,
        CascadedFields,
        draggable,
        AddFieldIcon,
        Checkbox,
        GripSectionIcon,
    },
    data() {
        return {
            oldSectionName: "",
            fieldsToSave: false,
            blockFieldsToSave: [],
        };
    },
    computed: {
        dragOptions() {
            return {
                animation: 0,
                group: "description",
                disabled: false,
                ghostClass: "ghost",
            };
        },
    },
    props: ["section", "checkData", "showSidePanel", "activeFieldPanel", "hideAddFieldButtonInBlock", "allReportsRoles", "updateSectionFieldsMethod", "updateBlockFieldsMethod", "updateFieldAttributesMethod"],
    methods: {
        isNumber,
        sectionNameClick(sectionData) {
            this.oldSectionName = sectionData.name;
        },
        showSaveBlockButton(element){
            if(element.expanded && this.hideAddFieldButtonInBlock){
                return true
            }else if(element.expanded && element.fields.length && element.entity_type === 'block' && element.created){
                return true
            }else{
                return false
            }
        },
        async updateSectionLabel(sectionData) {
            if (this.oldSectionName !== sectionData.name) {
                this.$emit("updateSectionLabel", sectionData)
            }
        },
        handleCollapseSection(section) {
            section.expanded = !section.expanded;
        },
        PermissionChange(role) {
            this.$emit("PermissionChange", role);
        },
        changeCaseCreation(payload) {
            this.$emit('changeCaseCreation', payload);
        },

        // section Modal control
        handleRemoveSection() {
            this.$emit("handleRemoveSection", this.section);
        },
        // onShowPanel(field, section) {
        //     this.$emit("handleShowPanel", { field, section });
        // },
        showFieldSettingsModal(field, section) {
            this.$emit("showFieldSettingsModal", { field, section });
        },
        showGapValidationModal(field, section) {
            this.$emit("showGapValidationModal", { field, section });
        },

        fieldNameClick(field) {
            this.$emit("fieldNameClick", field);
        },
        handleDeleteField(field, section) {
            this.$emit("handleDeleteField", { field, section });
        },
        updateFieldLabel(field, section, silent = false) {
            this.$emit("updateFieldLabel", { field, section, silent });
        },
        handleRemoveBlock(section, element) {
            this.$emit("handleRemoveBlock", { section, element });
        },
        handleCollapseBlock(section, element) {
            this.$emit("handleCollapseBlock", { section, element });
        },
        handleUpdateBlock(section, element) {
            if (element.block_multi && (parseInt(element.min_count, 10) > parseInt(element.max_count, 10))) {
                this.$toast.error("Max should be greater than or equal to min");
                return;
            } else
                this.$emit("handleUpdateBlock", { section, element });
        },
        saveBlockFields(section, element) {
            this.$emit("saveBlockFields", { section, element });
        },
        showfieldAddModal(section, element) {
            this.$emit("showfieldAddModal", { section, element });
        },
        fieldChanged(/* block = '' */) { // TODO note where to show save btn
            // if (block) {
            //     if (!this.blockFieldsToSave.includes(block)) this.blockFieldsToSave.push(block);
            // }
            // else this.fieldsToSave = true;
        },
        async updateSectionFields() { // TODO finish when API is ready
            try {
                await this.updateSectionFieldsMethod(this.section);
                this.fieldsToSave = false;
            } catch (error) {
                console.log('Save section fields error', error);
            }
        },
        async updateBlockFields(element) { // TODO finish when API is ready
            try {
                const payload = {
                    ...element,
                    fields: element.fields.map(field => removeEmpty({
                        field_id: field.id,
                        internal_use_only: field.internal_use_only,
                        max_field_or_block_count: field.max_field_or_block_count,
                        min_field_or_block_count: field.min_field_or_block_count,
                        is_top_level: field.is_top_level,
                        report_visibility: field.report_visibility,
                        use_in_gap_validation: field.use_in_gap_validation,
                        history: field.history,
                        valid_gap: field.valid_gap,
                        permissions: field.roles,
                    }))
                };
                await this.updateBlockFieldsMethod(this.section, payload);
                this.blockFieldsToSave = this.blockFieldsToSave.filter(el => el.id !== element.id);
            } catch (error) {
                console.log(error);
                throw error;
            }
        },

        // End Section Modal control
    },
};
</script>

<style></style>
