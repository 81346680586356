<template>
  <div class="headerBlock flex flex-col text-base-content">
    <div class="headerBlock__title font-semibold text-sm">{{ title }}</div>
    <div class="headerBlock__icons flex gap-2.5 items-center">
      <div title="Read">
        <ReadIcon class="headerBlock__item w-5" fill="currentColor" />
      </div>
      <div title="Write">
        <WriteIcon class="headerBlock__item w-5" fill="currentColor" />
      </div>
      <div title="Visible">
        <VisibleIcon class="headerBlock__item w-5" fill="currentColor" />
      </div>
      <div title="Mandatory">
        <MandatoryIcon class="headerBlock__item w-5" fill="currentColor" />
      </div>
    </div>
  </div>
</template>

<script>
import ReadIcon from "@/assets/icons/roles/read.svg";
import WriteIcon from "@/assets/icons/roles/write.svg";
import VisibleIcon from "@/assets/icons/roles/visible.svg";
import MandatoryIcon from "@/assets/icons/roles/mandatory.svg";

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  components: {
    ReadIcon,
    WriteIcon,
    VisibleIcon,
    MandatoryIcon,
  },
}
</script>

<style lang="scss" scoped>
</style>